import { enqueueSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Modal, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import axios from 'axios';
import tshirt from '../images/tshirt.jpeg';
import tshirtnavy from '../images/tshirtnavy.jpeg';
import { Link, Redirect } from 'react-router-dom';
import { config } from '../Config/Config';
import Header from '../Header/Header';
import bhim from '../images/BHIM-UPI.jpg';



const PlaceOrderForm = (props) => {

    const [size, setSize] = useState('M');
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(599);
    const [totalCost, setTotalCost] = useState(599 + 150);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [color, setColor] = useState('White');
    const [imageOnTshirt, setImageOnTshirt] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('Card');
    const [nameOnCard, setNameOnCard] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [expDate, setExpDate] = useState('');
    const [cvv, setCvv] = useState('');
    const [upiId, setUpiId] = useState('');
    const [orderId, setOrderId] = useState('');
    const [showOrder, setShowOrder] = useState(true);
    const [isOrderPlaced, setIsOrderPlaced] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [qr, setQr] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [picture, setPicture] = useState('')
    // const [picture, setPicture] = useState('https://res.cloudinary.com/sceem/image/upload/v1732614064/advertisement_eas9z1.png')

    useEffect(() => {
        getArticleImageByPostIdWeb();
        payment();
    }, [])
    const handleSizeChange = (event) => {
        setSize(event.target.value);
    };
    const handleColorChange = (event) => {
        setColor(event.target.value);
    };
    const handleQuantityChange = (event) => {
        const qty = parseInt(event.target.value);
        setQuantity(qty);
        setTotalCost(qty * 150 + price * qty)
    };

    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };
    const handlePaymentMethodChange = (method) => {
        setPaymentMethod(method);
    };
    const handleChangeCVV = (e) => {
        const value = e.target.value;
        setCvv(value.replace(/./g, "•"));
        // setCvv(value.replace(/./g, "*"));
    };
    const getArticleImageByPostIdWeb = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + `user/getArticleImageByPostIdWeb/${props.match.params.id}`, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    setImageOnTshirt(response.data.post[0]?.image);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        // Local variables for errors
        let localNameError = '';
        let localPhoneNumberError = '';
        let localEmailError = '';

        // Validate name
        if (name.length < 3) {
            localNameError = 'Name must be at least 3 characters.';
        }

        // Validate phone number
        if (!/^\d{10}$/.test(phoneNumber)) {
            localPhoneNumberError = 'Phone number must be exactly 10 digits.';
        }

        // Validate email
        if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.(com|in)$/.test(email)) {
            localEmailError = 'Email must be valid and end with .com or .in';
        }

        // If there are any validation errors, do not proceed further
        if (localNameError || localPhoneNumberError || localEmailError) {
            // Set errors in state
            setNameError(localNameError);
            setPhoneNumberError(localPhoneNumberError);
            setEmailError(localEmailError);

            // Show snackbar with error message
            enqueueSnackbar('Please enter correct details', { variant: "error" });

            // Return early, stop API call
            return;
        }

        // If validation passes, proceed with API call
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        };

        const payload = {
            "name": name,
            "email": email,
            "size": size,
            "quantity": quantity,
            "color": color,
            "price": price,
            "totalCost": totalCost,
            "address": address,
            "phoneNumber": phoneNumber,
            "articleId": props.match.params.id,
            "transactionStatus": 1,
            "imageOnTshirt": imageOnTshirt
        };

        console.log("payload", payload);

        axios.post(config.userUrl + 'user/placeTshirtOrderByWeb', payload)
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    // Successfully placed the order, update state
                    setOrderId(response.data.order?.orderId);
                    setShowOrder(false);  // Hide the order form
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    }
    const postTransactionDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var payload = {
            "orderId": orderId,
            "paymentMethod": "UPI",
            "transactionImage": picture,
            "transactionStatus": 2,
        }
        console.log("payload", payload)
        axios.post(config.userUrl + 'user/updateOrderTransactionStatusByWeb', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    // enqueueSnackbar('Transaction Completed Successfully', { variant: "success" })
                    setShowModal(false)
                    setIsOrderPlaced(true);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    }
    const payment = () => {
        // let headers = {
        //     "Content-Type": 'application/json',
        //     "Authorization": "Bearer " + this.state.token,
        //     "applicationType": "mobile"
        // }
        const qrcode = require('qrcode');
        const vpa = config.vpa;
        const amount = totalCost + '.00';
        const upiUrl = `upi://pay?pa=${encodeURIComponent(vpa)}&mc=yourMerchantCode&tid=yourTransactionId&tr=yourReferenceId&tn=Payment%20Description&am=${encodeURIComponent(amount)}&cu=INR&url=yourCallbackUrl`;


        qrcode.toDataURL(upiUrl, (err, dataUrl) => {
            if (err) throw err;
            //  console.log('Data URI:', dataUrl);
            setQr(dataUrl)
        });
    };
    const uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "sceem_")
        data.append("cloud_name", "sceem")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }

    const handleImage = async (e) => {
        //console.log("fgh", e.target.files)
        if (e.target.files && e.target.files[0]) {
            const res = await uploadImage(e.target.files[0]);
            if (res) {
                //  console.log("res", res)
                setPicture(res)
            }
        }
    }
    const showPublish = () => {
        document.getElementById('publish').style.display = 'flex'
    }
    const showNone = () => {
        document.getElementById('publish').style.display = 'none'
    }
    // const handleSubmit = () => {
    //     const errors = {};
    //     if (paymentMethod === 'Card') {
    //         if (!name.trim() || !/^[a-zA-Z]+$/.test(name.trim())) {
    //             errors.name = 'Name is required';
    //         }
    //         if (!cardNumber.trim() || !/^\d{16}$/.test(cardNumber.trim())) {
    //             errors.cardNumber = 'Card number is required.';
    //         }
    //         if (!expDate.trim() || !/^(0[1-9]|1[0-2])\/20[2-9]\d$/.test(expDate.trim())) {
    //             errors.expDate = 'Expiration date is required.';
    //         }
    //         if (!cvv.trim()) {
    //             errors.cvv = 'CVV is required';
    //         }
    //     } else if (paymentMethod === 'upi') {
    //         if (!upiId.trim()) {
    //             errors.upiId = 'UPI ID is required';
    //         }
    //     }
    //     if (Object.keys(errors).length === 0) {
    //         var payload;
    //         if (paymentMethod === "Card") {
    //             payload = {
    //                 "size": size,
    //                 "quantity": quantity,
    //                 "color": color,
    //                 "name":name,
    //                 "email":email,
    //                 "price": price,
    //                 "address": address,
    //                 "phoneNumber": phoneNumber,
    //                 "nameOnCard": nameOnCard,
    //                 "cardNumber": cardNumber,
    //                 "expDate": expDate,
    //                 "cvv": cvv,
    //                 "paymentMethod": paymentMethod,
    //                 "articleId": parseInt(props.match.params.id),
    //                 "transactionStatus": 2
    //             }
    //         } else {
    //             payload = {
    //                 "name":name,
    //                 "email":email,
    //                 "size": size,
    //                 "quantity": quantity,
    //                 "color": color,
    //                 "price": price,
    //                 "address": address,
    //                 "phoneNumber": phoneNumber,
    //                 "upiId": upiId,
    //                 "paymentMethod": paymentMethod,
    //                 "articleId": parseInt(props.match.params.id),
    //                 "transactionStatus": 2
    //             }
    //         }
    //         console.log("payload", payload)
    //         axios.post(config.userUrl + 'user/placeTshirtOrderByWeb', payload)
    //             .then(function (response) {
    //                 console.log(response);
    //                 if (response.data.status === true) {
    //                     enqueueSnackbar('Order Placed Successfully', { variant: "success" })
    //                     setRedirect(true);
    //                 }
    //             })
    //             .catch(function (error) {
    //                 console.log("error", error);
    //             })
    //     } else {
    //         Object.values(errors).forEach((error) => {
    //             enqueueSnackbar(error, { variant: 'error' });
    //         });
    //     }
    // };
    const formatCardNumber = (cardNumber) => {
        if (!cardNumber) return '';
        const formatted = cardNumber.replace(/\s/g, '').match(/.{1,4}/g).join(' ');
        return formatted;
    };

    if (redirect) {
        return <Redirect
            to={{
                pathname: `/`
            }}
        />
    }
    return (
        <div >
            <Header />

            <div style={{ marginTop: "4rem" }}>
                <Row style={{ width: "100%" }}>
                    <Col md={4} xs={12}></Col>
                    {showOrder ?
                        <Col md={4} xs={12}>
                            <div className="mt-2 your-jitbits-order">Order Tshirt</div>
                            <div style={{ fontFamily: "Open Sans" }}>
                                <Form className="ml-4">
                                    <div style={{ border: "1px solid gray", borderRadius: "15px", padding: "5px" }}>
                                        <Row style={{ width: "100%" }} className='no-gutters'>
                                            <Col xs={5}>
                                                <center>
                                                    <div style={{ position: 'relative', display: 'grid' }}>
                                                        <img src={color === "White" ? tshirt : tshirtnavy} style={{ width: "80%" }} alt="T-shirt" />
                                                        <img
                                                            src={config.userUrl + imageOnTshirt}
                                                            style={color === "White" ? {
                                                                position: 'absolute',
                                                                top: '32%',
                                                                left: '38%',
                                                                width: '24%',
                                                                height: 'auto',
                                                            } : {
                                                                position: 'absolute',
                                                                top: '32%',
                                                                left: '38%',
                                                                width: '24%',
                                                                height: 'auto',
                                                                filter: 'invert(1)',
                                                            }}
                                                            alt="Overlay"
                                                        />
                                                        <span className="label-order-text">Rs.{price}</span>
                                                    </div>
                                                </center>
                                            </Col>
                                            <Col xs={7}>
                                                <Row style={{ width: "100%" }} className='mt-3 no-gutters'>
                                                    <Col>

                                                        <label className="label-order-text">Color</label>
                                                        <select type="text" className='form-control form-fontsize' value={color} onChange={handleColorChange}>
                                                            <option>White</option>
                                                            <option>Navy Blue</option>
                                                        </select>
                                                    </Col>
                                                    <Col>
                                                        <label className="label-order-text">Size</label>
                                                        <select type="text" className='form-control form-fontsize' value={size} onChange={handleSizeChange}>
                                                            <option>S</option>
                                                            <option>M</option>
                                                            <option>L</option>
                                                            <option>XL</option>
                                                            <option>XXL</option>
                                                        </select>
                                                    </Col>
                                                </Row>
                                                <Row style={{ width: "100%" }} className='no-gutters'>
                                                    <Col>
                                                        <label className="label-order-text">Quantity</label>
                                                        <select type="text" className='form-control form-fontsize' value={quantity} onChange={handleQuantityChange}>
                                                            {[1, 2, 3, 4, 5].map((qty) => (
                                                                <option key={qty} value={qty}>
                                                                    {qty}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col>
                                                        <label className="label-order-text" for="price">Total Cost</label>
                                                        <input type="text" className='form-control form-fontsize' readOnly value={` Rs.${totalCost}`} />

                                                        <div style={{ fontSize: "9px" }}>Incl. Rs.150, Postal Charges
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="mt-2" style={{ border: "1px solid gray", borderRadius: "15px", padding: "5px" }}>
                                        <div style={{ marginBottom: "10px" }}>
                                            <label className="label-order-text">Name</label>
                                            <input
                                                type="text"
                                                className='form-control label-order-text'
                                                placeholder="Enter your name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                            {nameError && <small style={{ color: 'red', fontSize: "10px", marginTop: "-2px", display: "block" }}>{nameError}</small>}
                                        </div>

                                        <div style={{ marginBottom: "10px" }}>
                                            <label className="label-order-text">Address</label>
                                            <textarea
                                                className='form-control label-order-text'
                                                rows={3}
                                                style={{ resize: "none", width: "100%", padding: "10px", borderRadius: "4px" }}
                                                placeholder="Enter your address"
                                                value={address}
                                                onChange={handleAddressChange}
                                            />
                                        </div>

                                        <div style={{ marginBottom: "10px" }}>
                                            <label className="label-order-text">Email</label>
                                            <input
                                                type="text"
                                                className='form-control label-order-text'
                                                placeholder="Enter your email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            {emailError && <small style={{ color: 'red', fontSize: "10px", marginTop: "-2px", display: "block" }}>{emailError}</small>}
                                        </div>

                                        <div style={{ marginBottom: "10px" }}>
                                            <label className="mt-1 label-order-text">Phone Number</label>
                                            <input
                                                className='form-control label-order-text'
                                                type="text"
                                                placeholder="Enter your phone number"
                                                value={phoneNumber}
                                                onChange={handlePhoneNumberChange}
                                            />
                                            {phoneNumberError && <small style={{ color: 'red', fontSize: "10px", marginTop: "-2px", display: "block" }}>{phoneNumberError}</small>}
                                        </div>
                                    </div>
                                    <center>
                                        {address !== "" && name !== "" && email !== "" && phoneNumber !== "" ?
                                            <Button variant="default" className="mt-4 payment-submit-button" onClick={handleSubmit}>Submit</Button>
                                            :
                                            <Button variant="default" disabled="true" className="mt-4 payment-submit-button" onClick={handleSubmit}>Submit</Button>
                                        }
                                    </center>
                                </Form>
                            </div>
                        </Col>
                        : isOrderPlaced ? (
                            // Show the "Order Not Found" image if order is not found
                            <div style={{ textAlign: 'center', marginTop: '30vh', fontFamily: "Open Sans" }}>
                                <center>
                                    <h3><b>Payment Completed Successfully</b></h3>
                                    <h5><b>Check your email to view tracking details</b></h5>
                                </center>
                            </div>) :
                            (<Col md={4} xs={12}>
                                <div className='mt-2 your-jitbits'>UPI Payment</div>
                                <div style={{ marginLeft: "15px", marginRight: "15px" }}>
                                    <center className="mt-3">
                                        <div className="nebutech-analytics-llp"><b>{config.companyName.toUpperCase()}</b></div>
                                        <img src={qr} style={{ width: "50%" }} />
                                        <div className="mt-1 scan">{config.vpa}</div>
                                        <div className="mt-3 scan">Scan and Pay with any UPI app</div>
                                        <div className="mt-2 scan">Purchase of Tshirt</div>
                                        <div className="mt-1 nebutech-analytics-llp"><b>&#8377; &nbsp;{totalCost}</b></div>
                                    </center><br /><br /><br />
                                    <center><Button variant="default" onClick={() => setShowModal(true)} className="upload-trans">Upload Transaction</Button></center>


                                </div>
                            </Col>)
                    }
                    {/* <Col md={4} xs={12}>
                        <div className='payment-details-container'>
                            <div className="mt-2 payment-methods">
                                <div
                                    className={paymentMethod === 'Card' ? 'active' : ''}
                                    onClick={() => handlePaymentMethodChange('Card')}
                                >
                                    Card
                                </div>
                                <div
                                    className={paymentMethod === 'Upi' ? 'active' : ''}
                                    onClick={() => handlePaymentMethodChange('Upi')}
                                >
                                    UPI
                                </div>
                            </div>
                            <div className="mt-3">
                                {paymentMethod === 'Card' && (
                                    <div className='ml-2'>
                                        <div className='form-group'>
                                            <label>Name on Card</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder="Name"
                                                value={nameOnCard}
                                                onChange={(e) => setNameOnCard(e.target.value)}
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label>Card Number</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder="Ex: 1234 1234 1234 1234"
                                                value={formatCardNumber(cardNumber)}
                                                onChange={(e) => setCardNumber(e.target.value.replace(/\s/g, ''))}
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label>Exp Date</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder="MM/YYYY"
                                                value={expDate}
                                                onChange={(e) => setExpDate(e.target.value)}
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label>CVV</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder="CVV"
                                                value={cvv}
                                                onChange={handleChangeCVV}
                                                maxLength="4"
                                            />
                                        </div>
                                    </div>
                                )}
                                {paymentMethod === 'Upi' && (
                                    <div className='form-group'>
                                        <label>UPI ID</label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            placeholder="UPI ID"
                                            value={upiId}
                                            onChange={(e) => setUpiId(e.target.value)}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col> */}
                    <Col md={4} xs={12}></Col>
                </Row >
                <Modal
                    show={showModal}
                    size="md"
                    centered
                    aria-labelledby="upload-modal-title"
                >
                    <Modal.Header closeButton onClick={() => setShowModal(false)}>
                        <Modal.Title id="upload-modal-title">Upload Transaction Image</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container">
                            <center style={{ height: "150px" }}>
                                <label htmlFor="upload-button">
                                    {picture != "" ? (
                                        <img src={picture} style={{ width: "100px", height: "100px" }} />
                                    ) : (
                                        <>
                                            <i class="fa fa-upload fa-2x" aria-hidden="true"></i>
                                            <div className="up-load">Upload Image</div>
                                        </>
                                    )}
                                </label>
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    id="upload-button"
                                    name="image"
                                    onChange={(e) => handleImage(e)}
                                />
                            </center>

                            <center>
                                {picture !== "" ? (
                                    <Button
                                        variant="default"
                                        onClick={postTransactionDetails}
                                        className="mb-2 cancelbutton"
                                    >
                                        Yes
                                    </Button>
                                ) : (
                                    <Button
                                        variant="default"
                                        disabled={true}
                                        className="mb-2 cancelbutton"
                                    >
                                        Yes
                                    </Button>
                                )}
                                &nbsp;&nbsp;
                                <Button
                                    variant="default"
                                    onClick={() => setShowModal(false)}
                                    className="mb-2 deletebutton"
                                >
                                    No
                                </Button>
                            </center>
                        </div>
                    </Modal.Body>
                </Modal>

            </div >
        </div >
    );
};

export default PlaceOrderForm;