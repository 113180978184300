// import React, { useState, useEffect } from 'react';
// import './TrackingLink.css';
// import { Row, Col, Button } from 'react-bootstrap';
// import moment from 'moment-timezone';
// import Header from '../Header/Header';
// import { config } from '../Config/Config';
// import axios from 'axios';

// // Add the path to your "Not Found" image here
// import notfound from '../images/notfound.png';

// function TrackingLink(props) {
//     const [orderStatus, setOrderStatus] = useState('');
//     const [orderId, setOrderId] = useState('');
//     const [createdAt, setCreatedAt] = useState('');
//     const [updatedAt, setUpdatedAt] = useState('');
//     const [deliveryDate, setDeliveryDate] = useState('');
//     const [dispatchedDate, setDispatchedDate] = useState('');
//     const [packedOnDate, setPackedOnDate] = useState('');
//     const [trackingNumber, setTrackingNumber] = useState('');
//     const [showOrder, setShowOrder] = useState(true);
//     const [errorMessage, setErrorMessage] = useState('');
//     const [isOrderNotFound, setIsOrderNotFound] = useState(false);
//     const [isDisabled, setIsDisabled] = useState(false);

//     useEffect(() => {
//         // Perform any necessary setup
//         setOrderId(props.match.params.id)
//         if (props.match.params.id) {
//             getOrderByIdWeb();
//         }
//     }, []);

//     const getOrderByIdWeb = () => {
//         let headers = {
//             "Content-Type": 'application/json',
//             "applicationType": "web"
//         }
//         if (!orderId) {
//             setErrorMessage('Please enter a valid order ID.');
//             return;
//         }
//         axios.get(config.userUrl + `user/getOrderByIdWeb/${orderId}`, { headers: headers })
//             .then(function (response) {
//                 if (response.data.status === true && response.data.order.length > 0) {
//                     setShowOrder(false);
//                     setIsDisabled(true);
//                     setOrderId(response.data.order[0]?.orderId);
//                     setUpdatedAt(response.data.order[0]?.updatedAt);
//                     setCreatedAt(response.data.order[0]?.createdAt);
//                     setDispatchedDate(response.data.order[0]?.dispatchedDate);
//                     setPackedOnDate(response.data.order[0]?.packedOnDate);
//                     setDeliveryDate(response.data.order[0]?.deliveryDate);
//                     setOrderStatus(response.data.order[0]?.orderStatus);
//                     setTrackingNumber(response.data.order[0]?.trackingNumber)
//                     setIsOrderNotFound(false);  // Reset "Order Not Found" state
//                     setErrorMessage(''); // Clear any previous error message
//                 } else {
//                     setIsOrderNotFound(true);  // Set state to show "Not Found" image
//                     setErrorMessage('Order not found');
//                     setShowOrder(false); // Hide the input box if order is not found
//                 }
//             })
//             .catch(function (error) {
//                 setErrorMessage('Error fetching order. Please try again later.');
//                 setIsOrderNotFound(true);  // Set state to show "Not Found" image on error
//                 console.log("Error:", error);
//             });
//     };

//     const createdAtDate = moment(createdAt);
//     const estimatedDelivery = createdAtDate.add(5, 'days').format("DD-MM-YYYY");

//     return (
//         <div>
//             <Header />
//             <Row style={{ width: "100%", marginTop: "12vh" }}>
//                 <Col md={4} xs={12}></Col>
//                 <Col md={4} xs={12}>
//                     <div style={{ marginLeft: "28px" }}>
//                         {/* Show input and submit button initially */}
//                         <div className="d-none d-md-flex" style={{ padding: "10px", justifyContent: "center", alignItems: "center", display: "flex" }}>
//                             <div style={{ display: "flex", alignItems: "center" }}>
//                                 <b style={{ marginRight: "10px" }}>Order reference# </b>
//                                 <input
//                                     type="text"
//                                     name="orderId"
//                                     disabled={isDisabled}
//                                     value={orderId}
//                                     style={isDisabled ? { border: "1px solid black", background: "lightgray", marginRight: "10px", paddingLeft: "4px" } : { border: "1px solid black", marginRight: "10px", paddingLeft: "4px" }}
//                                     onChange={(e) => setOrderId(e.target.value)}
//                                 />
//                                 &nbsp;
//                                 {orderId ? (
//                                     <Button
//                                         variant="default"
//                                         onClick={getOrderByIdWeb}
//                                         disabled={isDisabled}
//                                         style={{
//                                             color: "white",
//                                             background: "#31197C",
//                                             width: "auto",
//                                             marginTop: "-3px",
//                                             height: "2rem",
//                                             padding: "2px 10px 3px 10px"
//                                         }}
//                                     >
//                                         Submit
//                                     </Button>
//                                 ) : (
//                                     <Button
//                                         variant="default"
//                                         disabled={true}
//                                         style={{
//                                             color: "white",
//                                             background: "#31197C",
//                                             width: "auto",
//                                             marginTop: "-3px",
//                                             height: "2rem",
//                                             padding: "2px 10px 3px 10px"
//                                         }}
//                                     >
//                                         Submit
//                                     </Button>
//                                 )}
//                             </div>
//                         </div>
//                         <div className="d-block d-md-none">
//                             <div style={{ display: "flex", justifyContent: "space-between" }}>
//                                 <div style={{ flex: 1 }}>    <b>Order reference# </b></div>
//                                 <div style={{ flex: 1 }}>
//                                     <input
//                                         type="text"
//                                         name="orderId"
//                                         value={orderId}
//                                         onChange={(e) => setOrderId(e.target.value)}
//                                         style={{ border: "1px solid black", width: "100%", paddingLeft: "4px" }}
//                                     />
//                                 </div>
//                             </div>
//                             &nbsp;
//                             <center>
//                                 {orderId ? (
//                                     <Button
//                                         variant="default"
//                                         onClick={getOrderByIdWeb}
//                                         style={{
//                                             color: "white",
//                                             background: "#31197C",
//                                             width: "auto",
//                                             marginTop: "5px",
//                                             height: "2rem",
//                                             padding: "2px 10px 3px 10px"
//                                         }}
//                                     >
//                                         Submit
//                                     </Button>
//                                 ) : (
//                                     <Button
//                                         variant="default"
//                                         disabled={true}
//                                         style={{
//                                             color: "white",
//                                             background: "#31197C",
//                                             width: "auto",
//                                             marginTop: "5px",
//                                             height: "2rem",
//                                             padding: "2px 10px 3px 10px"
//                                         }}
//                                     >
//                                         Submit
//                                     </Button>
//                                 )}
//                             </center>
//                         </div>
//                         {isOrderNotFound ?
//                             <div style={{ textAlign: 'center', marginTop: '15vh', fontFamily: "Open Sans" }}>
//                                 <center>
//                                     <img src={notfound} alt="Order Not Found" style={{ maxWidth: '100%', height: 'auto' }} />
//                                     <h3><b>Order not found</b></h3>
//                                     <h5><b>Check entered order number</b></h5>
//                                 </center>
//                             </div> : ""}
//                         {!showOrder && !isOrderNotFound ?
//                             <div className="mt-2 tracking-container">
//                                 <center className="tracking-heading">Tracking Details</center>
//                                 <div className="mt-4 header">
//                                     <span>
//                                         Estimated Delivery<br />
//                                         <span className="est-date">{createdAt ? estimatedDelivery : ""}</span>
//                                     </span>
//                                     <span>
//                                         Order ID<br />
//                                         <span className="est-date">{orderId}</span>
//                                     </span>
//                                 </div>
//                                 <div className="t-container">
//                                     <div className="timeline-block timeline-block-right">
//                                         <div className="marker"></div>
//                                         <div className="timeline-content">
//                                             <div className="mt-1">Order Placed</div>
//                                             <span className="timeline-text">
//                                                 {moment(createdAt).format("DD-MM-YYYY")}
//                                             </span>
//                                         </div>
//                                     </div>
//                                     <div className="timeline-block timeline-block-left">
//                                         <div
//                                             className={orderStatus === "Packed" || orderStatus === "Dispatched" || orderStatus === "Delivered"
//                                                 ? "marker"
//                                                 : "marker-empty"
//                                             }
//                                         ></div>
//                                         <div className="timeline-content">
//                                             <div className="mt-1">Packed</div>
//                                             <span className="timeline-text">
//                                                 {orderStatus === "Packed" || orderStatus === "Dispatched" || orderStatus === "Delivered"
//                                                     ? moment(packedOnDate).format("DD-MM-YYYY")
//                                                     : ""}
//                                             </span>
//                                         </div>
//                                     </div>
//                                     <div className="timeline-block timeline-block-right">
//                                         <div
//                                             className={orderStatus === "Dispatched" || orderStatus === "Delivered"
//                                                 ? "marker"
//                                                 : "marker-empty"
//                                             }
//                                         ></div>
//                                         <div className="timeline-content">
//                                             <div className="mt-1">Dispatched</div>
//                                             <span className="timeline-text">
//                                                 {orderStatus === "Dispatched" || orderStatus === "Delivered" ?
//                                                     moment(dispatchedDate).format("DD-MM-YYYY")
//                                                     : ""}<br />
//                                                 {orderStatus === "Dispatched" || orderStatus === "Delivered" ?
//                                                     <span>
//                                                         Tracking no<br />{trackingNumber}
//                                                     </span> : ""}
//                                             </span>
//                                         </div>
//                                     </div>

//                                     <div className="timeline-block timeline-block-left">
//                                         <div
//                                             className={orderStatus === "Delivered" ? "marker" : "marker-empty"}
//                                         ></div>
//                                         <div className="timeline-content">
//                                             <div className="mt-1">Delivered</div>
//                                             <span className="timeline-text">
//                                                 {deliveryDate ? moment(deliveryDate).format("DD-MM-YYYY") : ""}
//                                             </span>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div> : ""}
//                     </div>
//                 </Col>
//                 <Col md={4} xs={12}></Col>
//             </Row>
//         </div>
//     );
// }

// export default TrackingLink;






























import React, { useState, useEffect } from 'react';
import './TrackingLink.css';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment-timezone';
import Header from '../Header/Header';
import { config } from '../Config/Config';
import axios from 'axios';

// Add the path to your "Not Found" image here
import notfound from '../images/notfound.png';

function TrackingLink(props) {
    const [orderStatus, setOrderStatus] = useState('');
    const [orderId, setOrderId] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [updatedAt, setUpdatedAt] = useState('');
    const [deliveryDate, setDeliveryDate] = useState('');
    const [dispatchedDate, setDispatchedDate] = useState('');
    const [packedOnDate, setPackedOnDate] = useState('');
    const [trackingNumber, setTrackingNumber] = useState('');
    const [showOrder, setShowOrder] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [isOrderNotFound, setIsOrderNotFound] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        if (props.match.params.id) {
            setOrderId(props.match.params.id);
            getOrderByIdWeb(props.match.params.id); // Call the API when the component mounts with the order ID
        }
    }, [props.match.params.id]);

    const getOrderByIdWeb = (orderId) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        if (!orderId) {
            setErrorMessage('Please enter a valid order ID.');
            return;
        }
        axios.get(config.userUrl + `user/getOrderByIdWeb/${orderId}`, { headers: headers })
            .then(function (response) {
                if (response.data.status === true && response.data.order.length > 0) {
                    setShowOrder(false);
                    setIsDisabled(true);
                    setOrderId(response.data.order[0]?.orderId);
                    setUpdatedAt(response.data.order[0]?.updatedAt);
                    setCreatedAt(response.data.order[0]?.createdAt);
                    setDispatchedDate(response.data.order[0]?.dispatchedDate);
                    setPackedOnDate(response.data.order[0]?.packedOnDate);
                    setDeliveryDate(response.data.order[0]?.deliveryDate);
                    setOrderStatus(response.data.order[0]?.orderStatus);
                    setTrackingNumber(response.data.order[0]?.trackingNumber);
                    setIsOrderNotFound(false);  // Reset "Order Not Found" state
                    setErrorMessage(''); // Clear any previous error message
                } else {
                    setIsOrderNotFound(true);  // Set state to show "Not Found" image
                    setErrorMessage('Order not found');
                    setShowOrder(false); // Hide the input box if order is not found
                }
            })
            .catch(function (error) {
                setErrorMessage('Error fetching order. Please try again later.');
                setIsOrderNotFound(true);  // Set state to show "Not Found" image on error
                console.log("Error:", error);
            });
    };

    const createdAtDate = moment(createdAt);
    const estimatedDelivery = createdAtDate.add(5, 'days').format("DD-MM-YYYY");

    return (
        <div>
            <Header />
            <Row style={{ width: "100%", marginTop: "12vh" }}>
                <Col md={4} xs={12}></Col>
                <Col md={4} xs={12}>
                    <div style={{ marginLeft: "28px" }}>
                        {/* Case when orderId exists */}
                        {props.match.params.id && !isOrderNotFound ? (
                            <div>
                                <div className="d-none d-md-flex" style={{ padding: "10px", justifyContent: "center", alignItems: "center", display: "flex" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <b style={{ marginRight: "10px" }}>Order reference# </b>
                                        <input
                                            type="text"
                                            name="orderId"
                                            disabled={isDisabled}
                                            value={orderId}
                                            style={isDisabled ? { border: "1px solid black", background: "lightgray", marginRight: "10px", paddingLeft: "4px" } : { border: "1px solid black", marginRight: "10px", paddingLeft: "4px" }}
                                            onChange={(e) => setOrderId(e.target.value)}
                                        />
                                        &nbsp;
                                        <Button
                                            variant="default"
                                            onClick={() => getOrderByIdWeb(orderId)}
                                            disabled={isDisabled}
                                            style={{
                                                color: "white",
                                                background: "#31197C",
                                                width: "auto",
                                                marginTop: "-3px",
                                                height: "2rem",
                                                padding: "2px 10px 3px 10px"
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                                <div className="d-block d-md-none">
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ flex: 1 }}>    <b>Order reference# </b></div>
                                        <div style={{ flex: 1 }}>
                                            {/* <input
                                                type="text"
                                                name="orderId"
                                                value={orderId}
                                                onChange={(e) => setOrderId(e.target.value)}
                                                style={{ border: "1px solid black", width: "100%", paddingLeft: "4px" }}
                                            /> */}
                                            <input
                                                type="text"
                                                name="orderId"
                                                disabled={isDisabled}
                                                value={orderId}
                                                style={isDisabled ? { border: "1px solid black", width: "100%", background: "lightgray", paddingLeft: "4px" } : { border: "1px solid black", width: "100%", paddingLeft: "4px" }}
                                                onChange={(e) => setOrderId(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    &nbsp;
                                    <center>
                                        {orderId ? (
                                            <Button
                                                variant="default"
                                                disabled={isDisabled}
                                                onClick={() => getOrderByIdWeb(orderId)}
                                                style={{
                                                    color: "white",
                                                    background: "#31197C",
                                                    width: "auto",
                                                    marginTop: "5px",
                                                    height: "2rem",
                                                    padding: "2px 10px 3px 10px"
                                                }}
                                            >
                                                Submit
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="default"
                                                disabled={true}
                                                style={{
                                                    color: "white",
                                                    background: "#31197C",
                                                    width: "auto",
                                                    marginTop: "5px",
                                                    height: "2rem",
                                                    padding: "2px 10px 3px 10px"
                                                }}
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </center>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="d-none d-md-flex" style={{ padding: "10px", justifyContent: "center", alignItems: "center", display: "flex" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <b style={{ marginRight: "10px" }}>Order reference# </b>
                                        <input
                                            type="text"
                                            name="orderId"
                                            disabled={isDisabled}
                                            value={orderId}
                                            style={isDisabled ? { border: "1px solid black", background: "lightgray", marginRight: "10px", paddingLeft: "4px" } : { border: "1px solid black", marginRight: "10px", paddingLeft: "4px" }}
                                            onChange={(e) => setOrderId(e.target.value)}
                                        />
                                        &nbsp;
                                        <center>
                                            {orderId ? (
                                                <Button
                                                    variant="default"
                                                    onClick={() => getOrderByIdWeb(orderId)}
                                                    style={{
                                                        color: "white",
                                                        background: "#31197C",
                                                        width: "auto",
                                                        marginTop: "-3px",
                                                        height: "2rem",
                                                        padding: "2px 10px 3px 10px"
                                                    }}
                                                >
                                                    Submit
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="default"
                                                    disabled={true}
                                                    style={{
                                                        color: "white",
                                                        background: "#31197C",
                                                        width: "auto",
                                                        marginTop: "-3px",
                                                        height: "2rem",
                                                        padding: "2px 10px 3px 10px"
                                                    }}
                                                >
                                                    Submit
                                                </Button>
                                            )}
                                        </center>
                                    </div>
                                </div>
                                <div className="d-block d-md-none">
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ flex: 1 }}>    <b>Order reference# </b></div>
                                        <div style={{ flex: 1 }}>
                                            <input
                                                type="text"
                                                name="orderId"
                                                disabled={isDisabled}
                                                value={orderId}
                                                style={isDisabled ? { border: "1px solid black", width: "100%", background: "lightgray", paddingLeft: "4px" } : { border: "1px solid black", width: "100%", paddingLeft: "4px" }}
                                                onChange={(e) => setOrderId(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    &nbsp;
                                    <center>
                                        {orderId ? (
                                            <Button
                                                variant="default"
                                                onClick={() => getOrderByIdWeb(orderId)}
                                                style={{
                                                    color: "white",
                                                    background: "#31197C",
                                                    width: "auto",
                                                    marginTop: "5px",
                                                    height: "2rem",
                                                    padding: "2px 10px 3px 10px"
                                                }}
                                            >
                                                Submit
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="default"
                                                disabled={true}
                                                style={{
                                                    color: "white",
                                                    background: "#31197C",
                                                    width: "auto",
                                                    marginTop: "5px",
                                                    height: "2rem",
                                                    padding: "2px 10px 3px 10px"
                                                }}
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </center>
                                </div>
                            </div>
                        )}

                        {/* Case for 'Order Not Found' */}
                        {isOrderNotFound ? (
                            <div style={{ textAlign: 'center', marginTop: '15vh', fontFamily: "Open Sans" }}>
                                <center>
                                    <img src={notfound} alt="Order Not Found" style={{ maxWidth: '100%', height: 'auto' }} />
                                    <h3><b>Order not found</b></h3>
                                    <h5><b>Check entered order number</b></h5>
                                </center>
                            </div>
                        ) : (
                            <div>
                                {/* Show tracking details when order found */}
                                {!showOrder && !isOrderNotFound ? (
                                    <div className="mt-2 tracking-container">
                                        <center className="tracking-heading">Tracking Details</center>
                                        <div className="mt-4 header">
                                            <span>
                                                Estimated Delivery<br />
                                                <span className="est-date">{createdAt ? estimatedDelivery : ""}</span>
                                            </span>
                                            <span>
                                                Order ID<br />
                                                <span className="est-date">{orderId}</span>
                                            </span>
                                        </div>
                                        {/* Tracking Timeline */}
                                        <div className="t-container">
                                            <div className="timeline-block timeline-block-right">
                                                <div className="marker"></div>
                                                <div className="timeline-content">
                                                    <div className="mt-1">Order Placed</div>
                                                    <span className="timeline-text">
                                                        {moment(createdAt).format("DD-MM-YYYY")}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="timeline-block timeline-block-left">
                                                <div
                                                    className={orderStatus === "Packed" || orderStatus === "Dispatched" || orderStatus === "Delivered"
                                                        ? "marker"
                                                        : "marker-empty"
                                                    }
                                                ></div>
                                                <div className="timeline-content">
                                                    <div className="mt-1">Packed</div>
                                                    <span className="timeline-text">
                                                        {orderStatus === "Packed" || orderStatus === "Dispatched" || orderStatus === "Delivered"
                                                            ? moment(packedOnDate).format("DD-MM-YYYY")
                                                            : ""}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="timeline-block timeline-block-right">
                                                <div
                                                    className={orderStatus === "Dispatched" || orderStatus === "Delivered"
                                                        ? "marker"
                                                        : "marker-empty"
                                                    }
                                                ></div>
                                                <div className="timeline-content">
                                                    <div className="mt-1">Dispatched</div>
                                                    <span className="timeline-text">
                                                        {orderStatus === "Dispatched" || orderStatus === "Delivered" ? moment(dispatchedDate).format("DD-MM-YYYY") : ""}
                                                        {orderStatus === "Dispatched" || orderStatus === "Delivered" ? (
                                                            <span>
                                                                Tracking no<br />{trackingNumber}
                                                            </span>
                                                        ) : ""}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="timeline-block timeline-block-left">
                                                <div className={orderStatus === "Delivered" ? "marker" : "marker-empty"}></div>
                                                <div className="timeline-content">
                                                    <div className="mt-1">Delivered</div>
                                                    <span className="timeline-text">
                                                        {deliveryDate ? moment(deliveryDate).format("DD-MM-YYYY") : ""}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </div>
                </Col>
                <Col md={4} xs={12}></Col>
            </Row>
        </div>
    );
}

export default TrackingLink;